import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Container, Typography, Box, Table, TableHead,
  TableBody, TableRow, TableCell
} from '@mui/material';

function App() {
  const [mainPrompt, setMainPrompt] = useState('Datos de estudiantes peruanos de la región de puno con problemas con los videojuegos moba y de celular (nombres aleatorios no tan comunes)');
  const [columnData, setColumnData] = useState([
    { id: 1, name: 'ID', value: 'Que inicia en 1' },
    { id: 2, name: 'Nombres', value: '2 nombres (Asegurate de que sean dos)' },
    { id: 3, name: 'Apellidos', value: 'Apellido paterno y materno' },
    { id: 4, name: 'Edad', value: 'Edad de entre 14 y 25 años' },
    { id: 5, name: 'Adicto', value: 'A tenido problemas de adicción con los videojuegos? ("Si" o "No")' },
    { id: 6, name: 'Relato', value: 'Relato breve sobre su problema con los videojuegos (no uses "," en este texto en vez de coma usa ";")' },
    { id: 7, name: 'VideoJuego', value: 'Que video juego jugaba (Si no tuvo problemas colocar S/E)' },
    { id: 8, name: 'NumberPhone', value: 'Numero de celular' },
    { id: 9, name: 'Email', value: 'Correo electronico outlook gmail, hotmail (poca probabilidad de otro dominio)' }
  ]);
  const [respuesta, setRespuesta] = useState([]);
  const [csvContent, setCsvContent] = useState('');

  useEffect(() => {
    // Leer el contenido del CSV al cargar la página
    const storedCsvContent = localStorage.getItem('csvContent');
    if (storedCsvContent) {
      setCsvContent(storedCsvContent);
    }
  }, []);

  const enviarPOST = () => {
    const url = process.env.REACT_APP_API_URL;

    const fieldsGenString = columnData.map(({ name, value }) => `-${name}:${value}`).join(' ');

    const data = {
      mainPrompt: mainPrompt,
      fieldsGen: fieldsGenString
    };

    const jsonData = JSON.stringify(data);
    console.log('Cuerpo de la solicitud:', jsonData);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: jsonData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parsear la respuesta como JSON
      })
      .then(data => {
        console.log('Respuesta del servidor:', data);
        mostrarRespuesta(data);
        actualizarCSV(data); // Llamamos a la función para actualizar el CSV
      })
      .catch(error => {
        console.error('Error al enviar POST:', error);
      });
  };

  const mostrarRespuesta = (data) => {
    setRespuesta(data);
  };

  const handleColumnDataChange = (id, field, newValue) => {
    const newData = columnData.map(row =>
      row.id === id ? { ...row, [field]: newValue } : row
    );
    setColumnData(newData);
  };

  const agregarFilaAbajo = (index) => {
    const newId = Math.max(...columnData.map(row => row.id)) + 1;
    const newData = [
      ...columnData.slice(0, index + 1),
      { id: newId, name: '', value: '' },
      ...columnData.slice(index + 1)
    ];
    setColumnData(newData);
  };

  const eliminarFila = (id) => {
    const newData = columnData.filter(row => row.id !== id);
    setColumnData(newData);
  };

  const actualizarCSV = (data) => {
    const newCsvContent = csvContent + '\n' +
      data.map(item => Object.values(item).join(',')).join('\n');
    setCsvContent(newCsvContent);
    localStorage.setItem('csvContent', newCsvContent); // Guardar en el almacenamiento local
  };

  const generarCSV = () => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'generate.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const limpiarCSV = () => {
    setCsvContent('');
    localStorage.removeItem('csvContent');
  };

  return (
    <Container>
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
        <TextField
          label="¿Qué datos quieres Generar?"
          variant="outlined"
          value={mainPrompt}
          onChange={(e) => setMainPrompt(e.target.value)}
        />
        <Box sx={{ mt: 2, overflowX: 'auto' }}>
          <Table>
            <TableBody>
              {columnData.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: '150px' }}
                        value={row.name}
                        onChange={(e) => handleColumnDataChange(row.id, 'name', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        sx={{ width: '150px' }}
                        value={row.value}
                        onChange={(e) => handleColumnDataChange(row.id, 'value', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="error" size="small" onClick={() => eliminarFila(row.id)}>
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Button variant="contained" color="primary" size="small" onClick={() => agregarFilaAbajo(index)}>
                        Añadir Fila
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ mt: 2, textAlign: 'center', margin: '0 auto' }}>
          <Button variant="contained" size="large" sx={{ px: 18, py: 1.5, backgroundColor: '#0288ff' }} onClick={enviarPOST}>
            Generar
          </Button>
        </Box> 
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Datos Generados</Typography>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {respuesta.length > 0 && Object.keys(respuesta[0]).map((key, index) => (
                  <TableCell key={index} sx={{ fontWeight: 'bold', backgroundColor: '#5b7cb9', color: 'white' }}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {respuesta.map((item, index) => (
                <TableRow key={index}>
                  {Object.values(item).map((value, index) => (
                    <TableCell key={index}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
      {csvContent && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Todos Los Datos Generados:</Typography>
          <Box sx={{ mt: 2, textAlign: 'center', margin: '0 auto' }}>
            <Button variant="contained" size="large" sx={{ px: 20, py: 2, backgroundColor: '#f44336' }} onClick={limpiarCSV}>
              Limpiar
            </Button>
          </Box>
          <Box sx={{ overflowX: 'auto', maxHeight: '300px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnData.map((col, index) => (
                    <TableCell key={index} sx={{ fontWeight: 'bold', backgroundColor: '#5b7cb9', color: 'white' }}>{col.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {csvContent.split('\n').slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.split(',').map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 2, textAlign: 'center', margin: '0 auto' }}>
        <Button variant="contained" size="large" sx={{ px: 16, py: 1.5, backgroundColor: '#45c110' }} onClick={generarCSV}>
          Descargar
        </Button>
  
      </Box>
    </Container>
  );
}

export default App;
